import {inject} from "aurelia-framework";
import {Client} from "../api/client";
import {ModuleConfigClient} from "../api/module-config-client";
import * as _ from "lodash";
import {DialogService} from "aurelia-dialog";
import {Confirm} from '../dialog/confirm';
import {EventAggregator} from "aurelia-event-aggregator";
import {FlashService} from "../flash/flash-service";
import {I18N} from "aurelia-i18n";

@inject(
    DialogService,
    Client,
    ModuleConfigClient,
    EventAggregator,
    FlashService,
    I18N
)
export class WorkflowService {

    constructor(dialogService, client, moduleConfigClient, ea, flash, i18n) {
        this.dialog = dialogService;
        this.client = client;
        this.moduleConfigClient = moduleConfigClient;
        this.ea = ea;
        this.flash = flash;
        this.i18n = i18n;
    }

    async trigger(workflowId, idParameters, data) {
        console.debug('Triggering ' + workflowId, idParameters, data);

        let workflow = await this.moduleConfigClient.getWorkflow(workflowId);

        let response;

        try {
            if (idParameters) {
                response = await this.client.patch(
                    workflowId + '/trigger/' + idParameters.join(','),
                    data || {}
                );
            } else {
                response = await this.client.post(
                    workflowId + '/trigger',
                    data || {}
                );
            }
        } catch (err) {
            response = err;
        }

        this.ea.publish(
            'sio_form_post_submit',
            {config: {modelId: workflow.modelId}}
        );

        if (workflow.updateModelIds && workflow.updateModelIds.length > 0) {
            _.each(workflow.updateModelIds, (modelId) => {
                this.ea.publish(
                    'sio_form_post_submit',
                    {config: {modelId: modelId}}
                );
            });
        }

        let contexts = response.data;

        console.debug('workflow response', contexts);

        if (null != contexts && 0 < contexts.length) {
            for (let context of contexts) {
                if (context.message) {
                    this.flash[context.message_type || 'success'](context.message);
                }
            }
        } else {
            this.showError(response);
        }

        return contexts;
    }

    showError(response) {
        if (response?.data?.localizedMessage) {
            this.flash.error(response.data.localizedMessage);
        } else if (response?.data?.message) {
            this.flash.error(response.data.message);
        } else {
            this.flash.error('workflow_could_not_be_executed');
        }
    }
}
