import {DialogController, DialogService} from 'aurelia-dialog';
import {inject, LogManager, computedFrom, PLATFORM} from "aurelia-framework";
import {ModuleConfigClient} from "../../api/module-config-client";
import {ViewResolver} from "../../view/view-resolver.js";
import {FormServiceFactory} from "../../form/service/form-service-factory";
import {FlashService} from "../../flash/flash-service";
import {Client} from "../../api/client";
import {SubmitErrorHandler} from "../../form/submit-error-handler.js";
import {UnsavedChangesChecker} from "../../form/unsaved-changes-checker";
import {EventAggregator} from "aurelia-event-aggregator";
import {UnsavedChanges} from "../../form/unsaved-changes-prevent";
import {DocumentsPreviewDialog} from "../../documents-preview/documents-preview-dialog";

const logger = LogManager.getLogger('wizard-dialog');
//logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

@inject(
    DialogController,
    ModuleConfigClient,
    ViewResolver,
    FormServiceFactory,
    FlashService,
    Client,
    SubmitErrorHandler,
    UnsavedChangesChecker,
    EventAggregator,
    DialogService
)
export class WizardDialog {
    skipUnsavedChangesCheck = false;

    constructor(
        dialogController,
        moduleConfigClient,
        viewResolver,
        formServiceFactory,
        flashService,
        client,
        submitErrorHandler,
        unsavedChangesChecker,
        ea,
        dialog
    ) {
        this.dialogController = dialogController;
        this.moduleConfigClient = moduleConfigClient;
        this.viewResolver = viewResolver;
        this.formServiceFactory = formServiceFactory;
        this.flashService = flashService;
        this.client = client;
        this.submitErrorHandler = submitErrorHandler;
        this.unsavedChangesChecker = unsavedChangesChecker;
        this.ea = ea;
        this.dialog = dialog;
    }

    async activate(context) {
        if (!context.steps || context.steps.length < 1) {
            throw new Error("Wizard dialog must receive non-empty `steps` array where each item is id of view.");
        }

        const steps = context.steps.map(
            viewId => this.moduleConfigClient.getView(viewId)
        );

        let value = {};
        if (context.contextObj) {
            value = {
                contextObj: {
                    id: context.contextObj.id,
                    modelId: context.contextObj.modelId
                }
            }
        }

        await Promise.all(steps).then(
            views => {
                this.steps = views.map(
                    view => ({
                        view,
                        value: value
                    })
                );
            }
        );

        logger.debug("Fetched wizard steps", context, this.steps);

        this.context = context;
        this.successMessage = context.successMessage;
        this.updateModelIds = context.updateModelIds || [];

        this.currentStep = this.steps[0];

        this.contextObjectRef = context.contextObjectRef;
        this.contextObj = context.contextObj;
        this.bindValues = context.bindValues;

        this.originalUnsavedChanges = _.clone(UnsavedChanges.changes);
    }

    async canDeactivate()
    {
        if (this.skipUnsavedChangesCheck) {
            this.unsavedChangesChecker.revertChanges(this.originalUnsavedChanges);
            return true;
        }

        return this.unsavedChangesChecker.canDeactivate(this.originalUnsavedChanges);
    }

    @computedFrom('currentStep')
    get currentStepNumber()
    {
        return this.steps.indexOf(this.currentStep) + 1;
    }

    totalStepsNumber()
    {
        return this.steps.length;
    }

    @computedFrom('currentStep')
    get isFirstStep()
    {
        return this.steps.indexOf(this.currentStep) === 0;
    }

    @computedFrom('currentStep')
    get isLastStep()
    {
        return this.steps.indexOf(this.currentStep) === this.steps.length - 1;
    }

    prevStep()
    {
        if (this.isFirstStep) {
            this.dialogController.cancel();
        } else {
            this.currentStep = this.steps[this.steps.indexOf(this.currentStep) - 1];
        }
    }

    async nextStep()
    {
        this.loading = true;

        await this.updateCurrentStepValue();

        const requestData = await this.buildRequestData();

        try {
            const respondedValue = await this.client.post(
                this.currentStep.view.endpoint,
                requestData
            );

            if (this.isLastStep) {
                this.skipUnsavedChangesCheck = true;
                this.dialogController.ok().then(
                    () => {
                        this.flashService.success(this.successMessage);

                        this.updateModelIds.forEach(modelId => {
                            this.ea.publish('sio_form_post_submit', {config: {modelId}});
                        });
                    }
                ).then(
                    () => {
                        if (this.isLastStep && this.steps[0]?.value?.orders && this.currentStep.value?.sendEmail) {

                            const objects = [];

                            this.steps[0]?.value?.orders.forEach(orderId => {
                                objects.push("order/order-" + orderId);
                            });

                            this.dialog.open(
                                {
                                    viewModel: DocumentsPreviewDialog,
                                    model: Object.assign(this.context, {
                                        additionalData: {
                                            objects: objects,
                                            journey: this.steps[0]?.value?.journey,
                                            journeys: this.steps[0]?.value?.journeys,
                                        }
                                    })
                                }
                            );
                        }
                    }
                );
            } else {
                this.currentStep = this.steps[this.steps.indexOf(this.currentStep) + 1];
                this.currentStep.value = respondedValue.data;
            }
        } catch (e) {
            logger.debug('Handle wizard error', e, this.currentStep);

            this.submitErrorHandler.handleError(e, this.currentStep.form.formService);
        }

        this.loading = false;
    }

    async buildRequestData()
    {
        const requestData = {};
        this.steps.forEach(step => {
            requestData[step.view.id] = step.value;
        });

        return requestData;
    }

    async updateCurrentStepValue()
    {
        logger.debug("Update current step value", this.currentStep);

        this.currentStep.value = this.currentStep.form.formService.getValue();
    }
}

PLATFORM.moduleName('./wizard-steps.html');
