import {inject} from "aurelia-framework";
import {DialogService} from "aurelia-dialog";
import {WizardDialog} from "./wizard-dialog.js";

@inject(
    DialogService
)
export class Wizard
{
    constructor(
        dialog
    ) {
        this.dialog = dialog;
    }

    async open(returnContext)
    {
        await this.dialog.open({viewModel: WizardDialog, model: returnContext}).whenClosed();
    }
}
